export function replaceUmlauts(inputString) {
    return inputString
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ä/g, 'ae')
        .replace(/ß/g, 'ss')
    // Add more replacements as needed
}

export function routePathExists(route, allAvailableRoutes) {
    return allAvailableRoutes.some((r) => r.path === route)
}

export function reduceURL(url) {
    while (url.endsWith('/')) {
        url = url.slice(0, -1)
    }

    const lastSlashIndex = url.lastIndexOf('/')
    if (lastSlashIndex !== -1) {
        url = url.slice(0, lastSlashIndex + 1)
    }

    return url
}
