import _defaults from 'lodash/defaults.js'
import easyScroll from 'easy-scroll'

const scrollElement = process.server
    ? {}
    : document.scrollingElement || document.documentElement

function calculateDuration(
    distance,
    speed = 40 /* ms/100px */,
    baseDuration = 250,
) {
    return (Math.abs(distance) / 100) * speed + baseDuration
}

function toTop() {
    easyScroll({
        scrollableDomEle: scrollElement,
        direction: 'top',
        duration: calculateDuration(scrollElement.scrollTop),
        easingPreset: 'easeOutQuart',
        scrollAmount: Math.abs(scrollElement.scrollTop),
    })
}

// scrolls body, so that element top aligns with scroll position
function toElement(focusElement, options) {
    options = _defaults({}, options, {
        elementPosition: 'always',
        align: 'top',
        distance: 0,
    })

    try {
        const rect = focusElement.getBoundingClientRect()
        const navHeight =
            parseFloat(
                window
                    .getComputedStyle(scrollElement)
                    .getPropertyValue('--nav-height'),
            ) * 16

        const top = scrollElement.scrollTop
        const screenHeight = scrollElement.clientHeight
        let position = top + rect.y - navHeight

        if (options.align === 'bottom') {
            position += rect.height
        } else if (options.align === 'center') {
            position += rect.height / 2
        }

        if (options.distance) {
            position -= options.distance
        }

        const run =
            options.elementPosition === 'always' ||
            (options.elementPosition === 'above' && top > position) ||
            (options.elementPosition === 'below' && top < position)

        let scrollAmount = 0
        if (options.align === 'top') {
            scrollAmount = top - position
        } else if (options.align === 'bottom') {
            scrollAmount = top + screenHeight - position
        } else if (options.align === 'center') {
            scrollAmount = top + screenHeight / 2 - position
        }

        if (run) {
            easyScroll({
                scrollableDomEle: scrollElement,
                direction: scrollAmount > 0 ? 'top' : 'bottom',
                duration: calculateDuration(Math.abs(scrollAmount)),
                easingPreset: 'easeOutQuart',
                scrollAmount: Math.abs(scrollAmount),
            })
        }
    } catch (error) {
        console.warn(error)
    }
}

export function useScroll() {
    return {
        toTop,
        toElement,
    }
}
