<template>
    <div class="location">
        <header>
            <h2>Wie lautet Deine Postleitzahl?</h2>
        </header>

        <zip-code-check
            v-bind="settings"
            label=""
            :show-customer-groups="showCustomerGroups"
            @result="handleZipCodeCheckResult"
        />
    </div>
</template>

<script>
import useUserStore from '~/stores/user.js'
import useOrderStore from '~/stores/order.js'

export default {
    props: {
        showCustomerGroups: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['close', 'result'],

    async setup() {
        const [userStore, orderStore] = await Promise.all([
            useUserStore(),
            useOrderStore(),
        ])

        return {
            userStore,
            orderStore,
        }
    },

    data() {
        return {
            settings: {
                zipCode: '',
                city: '',
                customerGroup: Enums.customerGroups.private,
                tourType: '',
            },
        }
    },

    mounted() {
        this.settings = this.orderStore.settings
    },

    methods: {
        handleZipCodeCheckResult(serviceAvailable, data) {
            const vm = this
            if (!serviceAvailable) {
                vm.$emit('result', false)
                this.$emit('close')
                return
            }

            vm.$emit('result', data)
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
.location {
    padding: 0;
}

header {
    > h2 {
        margin-bottom: 1.5rem;
    }
}

:deep(.zip-code-check) {
    .form-control {
        box-shadow: none;
        border: 1px solid var(--rh-input-border-color);
    }
}

@screen sm {
    header {
        > h2 {
            margin-bottom: 2rem;
        }
    }
}
</style>
